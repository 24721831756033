<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="page-content-inner pt-lg-0 pr-lg-0 mt-4">
        <div class="uk-grid-medium" uk-grid>
          <div class="uk-width-5-5">
            <div class="section-header pb-0 mt-2 mb-4">
              <div class="section-header-left">
                <h3 class="uk-text-truncate">
                  <i class="uil-notebooks"></i> {{ $t('messenger.messages') }}
                </h3>
              </div>
            </div>

            <div class="chats-container margin-top-0">
              <div class="chats-container-inner">
                <!-- chats -->
                <div class="chats-inbox">
                  <div class="chats-headline">
                    <div class="input-with-icon">
                      <input id="autocomplete-input" v-model="search" :placeholder="$t('messenger.search')" type="text"
                        @input="isTyping = true" />
                      <i class="icon-material-outline-search"></i>
                    </div>
                  </div>

                  <template v-if="userListLoading">
                    <default-loading></default-loading>
                  </template>

                  <template>
                    <ul>
                      <li v-for="(item, key) in messengerSearchResults" :key="key">
                        <a href="#" @click.prevent="createMessageGroup(item)">
                          <div class="message-avatar">
                            <i v-if="item.status == true" class="status-icon status-online"></i>
                            <i v-if="item.status == false" class="status-icon status-offline"></i>
                            <img :src="item.photo_link" alt="" />
                          </div>

                          <div class="message-by">
                            <div class="message-by-headline">
                              <h5>{{ item.name + " " + item.surname }}</h5>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li v-for="(item, index) in userList" :key="index"
                        :class="item.chatId == activeChat.id ? 'active-message' : ''">
                        <a href="#" @click.prevent="openMessageGroup(item)">
                          <div class="message-avatar">
                            <i v-if="item.chatUserStatus == true" class="status-icon status-online"></i>
                            <i v-if="item.chatUserStatus == false" class="status-icon status-offline"></i>
                            <img :alt="item.chatName" :src="item.chatPhoto" />
                          </div>

                          <div class="message-by">
                            <div class="message-by-headline">
                              <h5>{{ item.chatName }}</h5>
                              <!--                             <span>2 gün önce</span>-->
                            </div>
                            <p v-if="item.chatLastMessage">{{ item.chatLastMessage }}</p>
                            <!-- <span class="message-readed uil-check"> </span> -->
                          </div>
                        </a>
                      </li>
                    </ul>
                  </template>
                </div>
                <!-- chats / End -->
                <!-- Message Content -->
                <template v-if="(messageGroupLoading || messagesLoading) && currentPage < 2">
                  <default-loading></default-loading>
                </template>
                <template v-else>
                  <div v-if="activeChat && activeChat.id" class="message-content">
                    <div class="chats-headline">
                      <div class="d-flex">
                        <div class="avatar-parent-child">
                          <img :alt="activeChat.name" :src="activeChat.photo" class="avatar rounded-circle avatar-sm" />
                          <span :class="activeChat.status ? 'bg-success' : 'bg-offline'"
                            class="avatar-child avatar-badge"></span>
                        </div>
                        <h4 class="ml-2">{{ activeChat.name }}
                          <span>{{ activeChat.status ? 'Çevirim İçi' : 'Çevirim Dışı' }}</span>
                        </h4>
                      </div>

                      <div class="message-action">
                        <router-link :to="{ name: 'user.show', params: { id: activeChat.userId } }"
                          uk-tooltip="Profiline bak" class="btn btn-icon btn-hover btn-circle">
                          <i class="uil-user"></i>
                        </router-link>
                      </div>
                    </div>

                    <!-- Message Content Inner -->
                    <div id="messages" class="message-content-inner" v-on:scroll="handleScroll">
                      <!-- Time Sign -->
                      <div class="message-time-sign">
                        <span>
                          <i v-if="currentPage > 1 && (messageGroupLoading || messagesLoading)"
                            class="fa fa-spinner fa-pulse"></i> </span>
                      </div>

                      <div v-for="(message, index) in messages" :key="index"
                        :class="message.sender_user_id == userInfo.id ? 'me' : ''" class="message-bubble">

                        <div class="message-bubble-inner" :id="'message_' + message.id">
                          <div class="message-avatar">
                            <img :alt="message && message.user ? message.user.fullname : ''"
                              :src="message && message.user ? message.user.photo_link : ''" />
                          </div>
                          <div class="message-text">
                            <p>
                              {{ message.message }}
                            </p>
                            <span class="time">{{ updateDateFormat(message.created_at) }}</span>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>

                      <div v-for="(item, index) in typingUsers" :key="index" class="message-bubble">
                        <div class="message-bubble-inner">
                          <div class="message-avatar">
                            <img :alt="item.fullName" :src="item.photo" />
                          </div>
                          <div class="message-text w-auto">
                            <!-- Typing Indicator -->
                            <div class="typing-indicator">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <span id="chat-box-area"></span>
                    </div>
                    <!-- Message Content Inner / End -->

                    <!-- Reply Area -->
                    <div class="message-reply">
                      <form class="d-flex align-items-center w-100">
                        <textarea v-model="message" cols="1" data-autoresize :placeholder="$t('general.write_your_message')"
                          rows="1" @keyup="messageTyping"></textarea>
                        <button class="send-btn d-inline-block btn btn-default" type="button"
                          style="width: auto !important;font-size: 1rem !important;padding: 10px !important;"
                          @click.prevent="sendMessage">Gönder</button>
                        <!-- <button  class="send-btn d-inline-block btn btn-default" type="button"
                          @click.prevent="sendMessage">
                          {{ $t("collectionDetail.send") }}
                          <i class="bx bx-paper-plane"></i>
                        </button> -->
                      </form>
                    </div>
                  </div>
                  <div v-else class="message-content d-flex justify-content-center align-items-center">
                    <p><img :src="company?.logo_link ? company.logo_link : ''" class="img-fluid avatar-xl"></p>
                    <p>{{ company?.name }}</p>
                  </div>
                </template>

                <!-- Message Button -->
                <!-- <button class="send-btn uk-button uk-button-primary" @click="showUser">+</button> -->
                <!-- Message Modal -->
                <div id="send-message" uk-modal>
                  <div class="uk-modal-dialog uk-modal-body p-3">
                    <button class="uk-modal-close-default" type="button"
                      style="top:32px !important;right:12px !important;" uk-close></button>
                    <div class="uk-modal-header uk-padding-remove-left">
                      <h3 class="uk-modal-title"><i class="uil-envelopes"></i> Mesaj Gönder</h3>
                    </div>
                    <div class="uk-modal-body p-0">
                      <!--step1-->
                      <ng-container class="toggle-usage">
                        <div class="input-with-icon mt-3">
                          <input id="" placeholder="Kişilerde Ara" type="text" />
                          <i class="icon-material-outline-search"></i>
                        </div>
                        <div style="height:400px; overflow-y:scroll;">
                          <table class="uk-table uk-table-middle uk-table-divider">
                            <tbody>
                              <tr v-for="(item, index) in allUsers.data" :key="index">
                                <td>
                                  <div class="media align-items-center">
                                    <div class="avatar-parent-child" style="width: max-content">
                                      <img src="" class="avatar rounded-circle" />
                                      <span :class="'bg-offline'" class="avatar-child avatar-badge"></span>
                                    </div>
                                    <div class="media-body ml-4">
                                      <span class="name h6 mb-0 text-sm">
                                        {{ item.name }}
                                      </span>
                                      <small class="d-block font-weight-bold">#356857</small>
                                    </div>
                                  </div>
                                </td>
                                <td><button class="uk-button uk-button-default" type="button"
                                    @click="openSendMessageScreen(item)"><i class="uil-envelopes"></i> Gönder</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <Pagination v-if="allUsers && allUsers.data && allUsers.meta.last_page !== 1"
                            :page.sync="pageProxy" :paginate="allUsers.meta">
                          </Pagination>
                        </div>
                      </ng-container>

                      <!--step2-->
                      <!-- <ng-container hidden class="toggle-usage">

                        <div class="media align-items-center mt-3 mb-3">
                          <div><button class="uk-button uk-button-link" uk-toggle="target: .toggle-usage"><i
                                class="uil-angle-left"></i></button></div>

                          <div class="avatar-parent-child" style="width: max-content">
                            <img src="" class="avatar rounded-circle" />
                            <span :class="'bg-offline'" class="avatar-child avatar-badge"></span>
                          </div>
                          <div class="media-body ml-4">
                            <span class="name h6 mb-0 text-sm">
                              Ali Güler
                            </span>
                            <small class="d-block font-weight-bold">#356857</small>
                          </div>
                        </div>
                        <textarea class="form-control" id="message_text" rows="2"
                          placeholder="Mesajınızı yazınız"></textarea>
                        <button class="uk-button uk-button-primary float-right" type="button">Gönder</button>

                      </ng-container> -->

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import messengerModule, {
  CREATE_MESSAGE,
  CREATE_MESSAGE_GROUP,
  GET_MESSAGE_GROUPS,
  GET_MESSAGES,
  GET_SEARCH,
  MESSAGE,
  MESSAGE_GROUP_LIST,
  MESSAGE_GROUP_LOADING,
  MESSAGE_LOADING,
  MESSAGES,
  MESSAGES_LOADING,
  MODULE_NAME,
  SEARCH,
  SEARCH_LOADING,
  SET_MESSAGE,
  SET_USER_LIST
} from "@/core/services/store/messenger.module";
import store from "@/core/services";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import Echo from 'laravel-echo';
import _ from "lodash";
import company_module, {
  MODULE_NAME as COMPANY_MODULE_NAME,
  ONLINE_USERS,
  SELF as COMPANY_SELF,
} from "@/core/services/store/company.module";
import { GET_ITEMS } from "@/core/services/store/REST.module";
import moment from "moment-timezone";
import EventBus from '@/view/components/event-bus'

window.io = require("socket.io-client");
import Pagination from "@/view/components/dataTable/Pagination";
const _MODULE_NAME = MODULE_NAME;
const _COMPANY_MODULE_NAME = COMPANY_MODULE_NAME;
import axios from "axios";
export default {
  name: "Messages",
  components: { DefaultLoading, Pagination },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, messengerModule);
    registerStoreModule(_COMPANY_MODULE_NAME, company_module);
  },
  data() {
    return {
      allUsers: [],
      chatUsers: [],
      typingUsers: [],
      messageGroupUrlList: 'api/message-groups',
      userListUrl: 'api/message-user-list',
      certificationsUrl: 'api/certification',
      createMessageGroupUrl: 'api/store-group',
      createMessageUrl: 'api/send-message',
      messagesUrl: 'api/get-messages',
      messagesCountUrl: "api/user-messages-count",
      certifications: [],
      search: '',
      order_by: 'name',
      sort: 'desc',
      isTyping: false,
      searchResult: [],
      chatMessages: [],
      currentPage: 1,
      lastPage: 0,
      activeChat: {
        id: null,
        name: '',
        status: '',
        photo: '',
      },
      page: 1,
      perPage: 10,
      allUserPage: 1,
      message: null,
      echoServer: null
    };
  },
  computed: {
    onlineUsers() {
      return store.getters[COMPANY_MODULE_NAME + "/" + ONLINE_USERS];
    },
    company() {
      return store.getters[COMPANY_MODULE_NAME + "/" + COMPANY_SELF];
    },
    userInfo: {
      get: function () {
        return this.authUser
      },
      set: function (newValue) {
      },
    },
    currentMessage() {
      return store.getters[_MODULE_NAME + "/" + MESSAGE];
    },
    messageLoading() {
      return store.getters[_MODULE_NAME + "/" + MESSAGE_LOADING];
    },
    messengerSearchResults() {
      let items = store.getters[_MODULE_NAME + "/" + SEARCH];
      if (!this.search || !items) return [];
      items.forEach(subItem => {
        subItem.status = this.onlineUsers.find(onlineUser => onlineUser.user == subItem.id && onlineUser.status) ? true : false
      })
      return items
    },
    userList() {
      let userLists = store.getters[_MODULE_NAME + "/" + MESSAGE_GROUP_LIST];
      let users = [];
      if (userLists && !Array.isArray(userLists)) {
        let newUserList = []
        for (const [key, value] of Object.entries(userLists)) {
          newUserList.push(value)
        }

        userLists = newUserList;
      }
      if (!userLists || !userLists.length || this.search) return users;
      userLists.forEach(item => {
        if (item.users.length == 2) {
          item.users.forEach(subItem => {
            if (subItem.id != this.userInfo.id) {
              users.push({
                chatId: item.id,
                chatUserId: subItem.id,
                chatName: subItem.fullname,
                chatPhoto: subItem.photo_link,
                chatLastMessage: item.messages.length && item.messages[0] && item.messages[0].hasOwnProperty('message') ? item.messages[0].message : "-",
                chatUserStatus: this.onlineUsers.find(onlineUser => onlineUser.user == subItem.id && onlineUser.status) ? true : false
              })
            }
          })
        } else {
          item.users.forEach(subItem => {
            if (subItem.id != this.userInfo.id) {
              users.push({
                chatId: item.id,
                chatUserId: subItem.id,
                chatName: item.name,
                chatPhoto: subItem.photo_link,
                chatLastMessage: item.messages.length && item.messages[0] && item.messages[0].hasOwnProperty('message') ? item.messages[0].message : "-",
                chatUserStatus: null,
              })
            }
          })
        }
      })
      console.log(users);
      return users
    },
    userListLoading() {
      return store.getters[_MODULE_NAME + "/" + SEARCH_LOADING];
    },
    messageGroupLoading() {
      return store.getters[_MODULE_NAME + "/" + MESSAGE_GROUP_LOADING];
    },
    newMessage() {
      return store.getters[_MODULE_NAME + "/" + MESSAGE];
    },
    messages() {
      let items = store.getters[_MODULE_NAME + "/" + MESSAGES];
      let self = this;
      if (!items) return [];
      this.chatUsers = items.groups.users;
      let messages = items.messages.data;
      this.lastPage = items.messages.last_page;
      messages.forEach(item => {
        item.user = self.chatUsers.find(subItem => subItem.id === item.sender_user_id);
      });

      if (this.chatMessages.length > 1) {
        let newMessages = messages.reverse();
        this.chatMessages = newMessages.concat(this.chatMessages)
      } else {

        this.chatMessages = messages.reverse();
      }
      return this.chatMessages;
    },
    messagesLoading() {
      return store.getters[_MODULE_NAME + "/" + MESSAGES_LOADING];
    },
  },
  methods: {
    pageProxy: {
      set(value) {
        this.allUserPage = value
        this.showUser()
      },
      get() {
        return this.page;
      }
    },
    openSendMessageScreen(event) {

      // let users = {
      //   chatId: 92,
      //   chatUserId: event.id,
      //   chatName: 'mustafa',
      //   chatPhoto: null,
      //   chatLastMessage: "asdsadsadas",
      //   chatUserStatus: null,
      // }
      // this.$store.dispatch(_MODULE_NAME + '/' + CREATE_MESSAGE_GROUP, {
      //   url: this.createMessageGroupUrl,
      //   contents: {
      //     users: users
      //   }
      // }).then(response => {
      //   if (response.status) {
      //     let responeData = response.data;
      //     debugger
      // let data = {
      //   chatId: '1',
      // }
      // this.getMessageGroups();
      // this.openMessageGroup(data);
      //   }
      // })
      // this.$store.dispatch(_MODULE_NAME + '/' + GET_SEARCH, {
      //   url: this.userListUrl,
      //   filters: {
      //     search: event.name,
      //   }
      // })
      this.$store.dispatch(_MODULE_NAME + '/' + GET_MESSAGES, {
        url: this.messagesUrl,
        filters: {
          user_id: event.id,
          group_id: this.activeChat.id,
          page: this.currentPage
        }
      })
      this.getMessagesCount();
    },
    showUser() {
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + '/api/company-users', {
        params: {
          search: null,
          page: this.allUserPage,
          per_page: 5,
        },
        headers: this.apiHeaders,
      }).then((response) => {
        console.log('allUser', response.data);
        this.allUsers = response.data;
      })
      UIkit.modal("#send-message").show();
    },
    updateDateFormat(date) {
      return moment.tz(date, "UTC").tz('Europe/Istanbul').format("DD MMMM, YYYY - HH:mm")
    },
    handleScroll: function (e) {
      let self = this;
      let left = e.target.scrollLeft;
      let top = e.target.scrollTop;

      let percentValue = e.target.scrollTop * 100 / e.target.scrollHeight;
      if (percentValue <= 10) {
        if (this.currentPage != this.lastPage) {
          const el = document.querySelector('#messages');
          el.scrollLeft = left;
          el.scrollTop = top + 100;
        }
        if (!self.messagesLoading) {
          self.getMessages();
        }
      }
    },
    messageTyping() {
      let conversationMessageId = this.activeChat.id;
      let username = [{
        photo: this.userInfo.photo,
        fullName: this.userInfo.fullname,
      }];
      this.echoServer.join(`chat-room-${conversationMessageId}`)
        .whisper('typing', username);
    },
    messengerSearch() {
      if (!this.search) {
        store.commit(_MODULE_NAME + '/' + SET_USER_LIST, null);
      } else {
        this.$store.dispatch(_MODULE_NAME + '/' + GET_SEARCH, {
          url: this.userListUrl,
          filters: {
            search: this.search,
          }
        })
      }
    },
    getMessageGroups() {
      this.$store.dispatch(_MODULE_NAME + '/' + GET_MESSAGE_GROUPS, {
        url: this.messageGroupUrlList,
        filters: {}
      })
    },
    createMessageGroup(user) {
      let userId = user['id'];
      let users = [userId];

      this.search = '';
      let userCheck = this.userList.find(item => item.chatUserId == userId);
      if (userCheck) {
        this.openMessageGroup(userCheck);
      } else {
        this.$store.dispatch(_MODULE_NAME + '/' + CREATE_MESSAGE_GROUP, {
          url: this.createMessageGroupUrl,
          contents: {
            users: users
          }
        }).then(response => {
          if (response.status) {
            let responeData = response.data;
            let data = {
              chatId: responeData.id,
              chatUserId: user.id,
              chatName: user.fullname,
              chatPhoto: user.photo_link,
              chatUserStatus: this.onlineUsers.find(onlineUser => onlineUser.user == user.id && onlineUser.status) ? true : false
            }
            this.getMessageGroups();
            this.openMessageGroup(data);
          }
        })
      }
    },
    sendMessage() {
      let content = {
        "message_group_id": this.activeChat.id,
        "sender_user_id": this.userInfo.id,
        "message": this.message
      }

      this.$store.dispatch(_MODULE_NAME + '/' + CREATE_MESSAGE, {
        url: this.createMessageUrl,
        contents: content
      })
    },
    scrollToEnd: function (elementId = null) {
      if (elementId) {
        document.getElementById(elementId).scrollIntoView({
          behavior: 'smooth'
        });
      } else {
        if (this.$el && this.$el.querySelector("#chat-box-area")) {
          document.getElementById("chat-box-area").scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    },
    openMessageGroup(item) {
      this.chatMessages = [];
      this.currentPage = 1;
      this.activeChat = {
        id: item.chatId,
        status: this.onlineUsers.find(onlineUser => onlineUser.user == item.chatUserId && onlineUser.status) ? true : false,
        userId: item.chatUserId,
        name: item.chatName,
        photo: item.chatPhoto,
      }
      let self = this;
      self.typingUsers = [];
      this.echoServer.join(`chat-room-${item.chatId}`)
        .listenForWhisper('typing', (data) => {
          self.typingUsers = data;
          self.scrollToEnd();
          setTimeout(function () {
            self.typingUsers = [];
          }, 2000);
        });

      let messageGroupId = item.chatId;

      this.echoServer.private(`Chat.${messageGroupId}`).listen('.message.send', (payload) => {
        if (payload && payload.message && payload.message.sender_user_id != self.userInfo.id) {
          let tmpMessage = Object.assign({}, payload.message);
          let user = self.chatUsers.find(subItem => subItem.id === tmpMessage.sender_user_id);
          tmpMessage = Object.assign({}, tmpMessage, { user: user });

          let messageHtml = '<div class="message-bubble">';
          messageHtml += '<div class="message-bubble-inner" id=" ' + tmpMessage.id + '">';
          messageHtml += '<div class="message-avatar">';
          messageHtml += '<img alt=" ' + tmpMessage.user.fullname + '" src="' + tmpMessage.user.photo_link + '"/>';
          messageHtml += ' </div><div class="message-text"><p>' + tmpMessage.message;
          messageHtml += ' </p><span class="time">' + self.updateDateFormat(tmpMessage.created_at) + '<span></div></div><div class="clearfix"></div></div>';
          $('#messages').append(messageHtml).fadeIn();
          self.scrollToEnd();
        }
      });

      this.$store.dispatch(_MODULE_NAME + '/' + GET_MESSAGES, {
        url: this.messagesUrl,
        filters: {
          user_id: this.userInfo.id,
          group_id: item.chatId,
          page: this.currentPage
        }
      })
      this.getMessagesCount();
    },
    getMessages() {
      if (this.currentPage < this.lastPage) {
        this.currentPage += 1;
        this.$store.dispatch(_MODULE_NAME + '/' + GET_MESSAGES, {
          url: this.messagesUrl,
          filters: {
            user_id: this.userInfo.id,
            group_id: this.activeChat.id,
            page: this.currentPage
          }
        })
      }
    },
    getMessagesCount() {
      let self = this;
      self.$store
        .dispatch(GET_ITEMS, {
          url: this.messagesCountUrl,
          filters: {},
          acceptPromise: false,
        })
        .then((response) => {
          EventBus.$emit('message_count')
          self.itemCount = response.data;
          UIkit.modal("#send-message").hide();

        });
    },
  },
  mounted() {
    this.getMessageGroups();
    this.echoServer = new Echo({
      broadcaster: "socket.io",
      host: process.env.VUE_APP_WEBSOCKET_URL,
      auth: {
        headers: {
          Authorization: "Bearer " + this.secureLocalStorage("token"),
        },
      },
      forceTLS: false,
      disableStats: true,
      encrypted: false,
      enabledTransports: ['ws', 'wss'],
    });
    let message = this.$route.params.message
    if (message && message.id) {
      let messageSetting = {

        chatId: message.message_group_id,
        chatUserId: message.sender_user_id,
        chatName: message.user.fullname,
        chatPhoto: message.user.photo_link,
        chatLastMessage: message.message,
        chatUserStatus: this.onlineUsers.find(onlineUser => onlineUser.user == message.user.id && onlineUser.status) ? true : false
      }
      this.openMessageGroup(messageSetting)
    }
    this.getMessagesCount();
  },
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.messengerSearch();
      }
    },
    newMessage: function (message) {
      if (message) {
        let self = this;
        let tmpMessage = Object.assign({}, message);
        let user = self.chatUsers.find(subItem => subItem.id === tmpMessage.sender_user_id);
        tmpMessage = Object.assign({}, message, { user: user });
        let messageHtml = '<div class="message-bubble me">';
        messageHtml += '<div class="message-bubble-inner" id=" ' + tmpMessage.id + '">';
        messageHtml += '<div class="message-avatar">';
        messageHtml += '<img alt=" ' + tmpMessage.user.fullname + '" src="' + tmpMessage.user.photo_link + '"/>';
        messageHtml += ' </div><div class="message-text"><p>' + tmpMessage.message;
        messageHtml += ' </p><span class="time">' + self.updateDateFormat(tmpMessage.created_at) + '<span></div></div><div class="clearfix"></div></div>';
        $('#messages').append(messageHtml).fadeIn();
        self.scrollToEnd();
        this.message = null;
      }
    }
  }
};
</script>

<style scoped>
.message-content {
  min-height: 70vh !important;
}

.time {
  display: block;
  text-align: right;
  margin-top: 15px;
  padding: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.message-content-inner {
  max-height: 50vh !important;
  overflow: scroll;
}

.bg-offline {
  background-color: #c0c0c0 !important;
}
</style>
